import { gql } from '@apollo/client';
import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as React from 'react';

import { EAccountCreationPermission } from '@/graphql-types/globalTypes';
import { useSSOProvider } from '@/imports/ui/pages/account-management/external-sso/useSSOProvider';
import { useQueryWithPastDataDeprecated } from '@/imports/ui/useQueryWithPastData';

import { PromptSignUpModal_CurrentUser } from './graphql-types/PromptSignUpModal_CurrentUser';

import { LogInSignUp } from './LogInSignUp';
import { EPromptState } from './utils';

const CURRENT_USER_AND_TENANT = gql`
  query PromptSignUpModal_CurrentUser($imageInput: DynamicImageInput!) {
    currentUser {
      _id
    }
    tenant {
      result {
        _id
        logo {
          _id
          imageProxy(input: $imageInput)
        }
        accountCreationPermission
        displayName
      }
    }
  }
`;

export const LogInSignUpModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  initialPromptState?: EPromptState;
  conference?: {
    useConferenceHeaders: boolean;
    name: string;
  };
}> = ({ isOpen, onClose, initialPromptState, conference }) => {
  const { ssoMetadata, loading } = useSSOProvider();

  const { data: currentUserData, loading: currentUserLoading } =
    useQueryWithPastDataDeprecated<PromptSignUpModal_CurrentUser, {}>(
      CURRENT_USER_AND_TENANT,
      {
        variables: { imageInput: { h: 160 } },
      }
    );
  const currentUserId = currentUserData?.currentUser?._id;

  if (loading || currentUserLoading || !currentUserData || !!currentUserId) {
    return null;
  }

  const allowSignUp =
    currentUserData?.tenant?.result?.accountCreationPermission ===
    EAccountCreationPermission.ANYONE;

  const promptStartState =
    allowSignUp && initialPromptState
      ? initialPromptState
      : EPromptState.LOGIN_FORM;

  const tenantData = currentUserData.tenant?.result;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalHeader p="6">
          {tenantData?.logo?.imageProxy && (
            <Image
              maxH="10"
              maxW="36"
              h="full"
              w="auto"
              mx="auto"
              borderRadius="base"
              src={tenantData?.logo?.imageProxy}
              alt="tenant logo"
            />
          )}
        </ModalHeader>

        <ModalBody p="6">
          <LogInSignUp
            initialPromptState={promptStartState}
            modal
            ssoProvider={ssoMetadata.ssoProvider}
            conferenceOptions={conference}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
