import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as queryString from 'query-string';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { logError } from '@/imports/logging/ClientLogger';
import { accountsClientPassword } from '@/modules/accounts/accounts-client';
import { EMAIL_PLACEHOLDER, PASSWORD_PLACEHOLDER, REG_EXP } from '@/utilities';

type LogInFormValues = {
  email: string;
  password: string;
};

export const LogInForm: React.FC<{
  acronym?: string;
  onClickResetPassword: () => void;
  header?: string;
  ssoProvider?: string;
  onSubmitLogin: () => void;
  onLoginOutcome: (outcome: string) => void;
  onClickSSO: () => void;
}> = (props) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [showLoginInputs, setShowLoginInputs] = React.useState(
    !props.ssoProvider
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isValid },
  } = useForm<LogInFormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async ({
    email,
    password,
  }: LogInFormValues): Promise<void> => {
    props.onSubmitLogin();
    setIsLoading(true);
    setHasError(false);
    try {
      await accountsClientPassword.login({ user: { email }, password });
      props.onLoginOutcome('tw-login-success');
      window.location.reload();
    } catch (err) {
      setIsLoading(false);
      logError(err);
      props.onLoginOutcome(
        err.message === 'User not found [403]'
          ? 'tw-login-error-wrong-email'
          : 'tw-login-error-wrong-pw'
      );
      setHasError(true);
    }
  };

  return (
    <Stack as="form" spacing="6" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Heading size="md">Sign in to your Account</Heading>

      {!!hasError && (
        <Alert status="error">
          <AlertIcon />
          {!!props.ssoProvider ? (
            <AlertDescription>
              There was a problem logging into this account. If you already have
              a membership with {props.acronym || 'the association'}, try the{' '}
              {props.acronym || 'association'} login method. Otherwise, try a
              different username or password.
            </AlertDescription>
          ) : (
            <AlertDescription>
              There was a problem logging into this account. Try a different
              username or password.
            </AlertDescription>
          )}
        </Alert>
      )}

      {!!props.header && (
        <Text align="center" color="gray.500">
          {props.header}
        </Text>
      )}

      {!!props.ssoProvider && (
        <>
          <Button
            w="full"
            height="auto"
            whiteSpace="normal"
            py="2.5"
            type="button"
            onClick={() => {
              props.onClickSSO();
              router.push(
                `/v1/sso?${queryString.stringify({
                  redirect: router.asPath,
                })}`
              );
            }}
          >
            Login with your Existing{' '}
            {props.acronym ? props.acronym : 'Association'} Account
          </Button>

          <Stack direction="row" alignItems="center">
            <Divider borderColor="gray.100" />
            <Text textStyle="compactStrong" color="gray.500">
              OR
            </Text>
            <Divider borderColor="gray.100" />
          </Stack>
        </>
      )}

      {showLoginInputs ? (
        <Stack spacing="6" mb="6">
          <FormControl isRequired isInvalid={!!errors.email}>
            <FormLabel requiredIndicator={<></>}>Email</FormLabel>
            <Input
              autoFocus
              type="email"
              placeholder={EMAIL_PLACEHOLDER}
              {...register('email', {
                required: 'Email is required',
                validate: {
                  email: (value) =>
                    !value ||
                    REG_EXP.EmailWithTLD.test(value) ||
                    'You must enter a valid email address',
                },
              })}
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.password}>
            <FormLabel requiredIndicator={<></>}>Password</FormLabel>
            <Input
              type="password"
              placeholder={PASSWORD_PLACEHOLDER}
              {...register('password', { required: 'Password is required' })}
              autoComplete="current-password"
            />
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>

          <Stack>
            <Button
              type="submit"
              w="full"
              isLoading={isLoading}
              variant={props.ssoProvider ? 'outline' : 'solid'}
              isDisabled={isSubmitted && !isValid}
            >
              Sign In
            </Button>

            <Box color="gray.600">
              <Button
                variant="link"
                colorScheme="black"
                onClick={props.onClickResetPassword}
              >
                Forgot your Password?
              </Button>
            </Box>
          </Stack>
        </Stack>
      ) : (
        <Button
          variant="link"
          colorScheme="black"
          fontWeight="normal"
          onClick={() => setShowLoginInputs(true)}
        >
          Sign in with your Tradewing Account
        </Button>
      )}
    </Stack>
  );
};
