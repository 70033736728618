import { useRouter } from 'next/router';
import * as React from 'react';

import { useAnalyticsUserProperties } from '@/hooks/useAnalyticsUserProperties';
import { useAnalytics } from '@/providers/contexts/AnalyticsContext';

import { AnalyticsLogger } from './AnalyticsLogger';
import { IAnalyticsLogger } from './types';

export const useAnalyticsLogger = (): IAnalyticsLogger => {
  const router = useRouter();
  const [location, setLocation] = React.useState<{
    pathname: string;
    search?: string;
  }>({ pathname: '' });

  // TODO figure out how to log page changes
  React.useEffect(() => {
    if (router.isReady && location.pathname !== router.asPath) {
      setLocation({ pathname: router.asPath });
    }
  }, [router, location]);
  const analyticsContext = useAnalytics();
  const { properties } = useAnalyticsUserProperties();
  const {
    associationCode,
    vendorDirectory,
    communityAccess,
    userType,
    email,
    tier,
    role,
    membershipStatus,
  } = properties;

  return AnalyticsLogger(location, analyticsContext, associationCode || null, {
    vendorDirectory,
    communityAccess,
    userType,
    email,
    tier,
    role,
    membershipStatus,
  });
};
