import { gql, useMutation } from '@apollo/client';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { logError } from '@/imports/logging/ClientLogger';
import { EMAIL_PLACEHOLDER, REG_EXP } from '@/utilities';

import {
  signUpForm_signUpProspectUser,
  signUpForm_signUpProspectUserVariables,
} from './graphql-types/signUpForm_signUpProspectUser';

export const signUpForm_signUpProspectUserAST = gql`
  mutation signUpForm_signUpProspectUser($input: SignUpProspectUserInput!) {
    signUpProspectUser(data: $input) {
      data {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;

type SignUpFormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

export const SignUpForm: React.FC<{
  header?: string;
  onSubmit: () => void;
}> = (props) => {
  const router = useRouter();

  const [signUpFunc, signUpResult] = useMutation<
    signUpForm_signUpProspectUser,
    signUpForm_signUpProspectUserVariables
  >(signUpForm_signUpProspectUserAST);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isValid },
    reset,
  } = useForm<SignUpFormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  const redirect = React.useMemo(() => {
    if (router.isReady) {
      return router.query.redirect?.toString() ?? router.asPath;
    }
    return undefined;
  }, [router]);

  const onSubmit = async ({
    firstName,
    lastName,
    email,
  }: SignUpFormValues): Promise<void> => {
    try {
      await signUpFunc({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            redirect,
          },
        },
      });
      props.onSubmit();
      reset();
    } catch (err) {
      logError(err);
    }
  };

  const foundExistingEmailMsg = 'An account with that email already exists.';

  return (
    <>
      {signUpResult.error && (
        <Alert status="error" mb="4">
          <AlertIcon />
          <AlertDescription>
            {signUpResult.error.message.includes(foundExistingEmailMsg)
              ? 'An account with that email already exists. Please log in.'
              : 'There was an error with your submission. Please try again later.'}
          </AlertDescription>
        </Alert>
      )}

      {!!props.header && (
        <Text align="center" color="gray.500" mb="8">
          {props.header}
        </Text>
      )}

      <Stack as="form" spacing="6" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Heading size="md">Create an Account</Heading>

        <FormControl isRequired isInvalid={!!errors.firstName}>
          <FormLabel requiredIndicator={<></>}>First Name</FormLabel>
          <Input
            autoFocus
            placeholder="First name"
            autoComplete="given-name"
            {...register('firstName', { required: 'First name is required' })}
          />
          <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.lastName}>
          <FormLabel requiredIndicator={<></>}>Last Name</FormLabel>
          <Input
            placeholder="Last name"
            autoComplete="family-name"
            {...register('lastName', { required: 'Last name is required' })}
          />
          <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel requiredIndicator={<></>}>Email</FormLabel>
          <Input
            type="email"
            placeholder={EMAIL_PLACEHOLDER}
            autoComplete="email"
            {...register('email', {
              required: 'Email is required',
              validate: {
                email: (value) =>
                  !value ||
                  REG_EXP.EmailWithTLD.test(value) ||
                  'You must enter a valid email address',
              },
            })}
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <Button
          w="full"
          isLoading={signUpResult.loading}
          isDisabled={isSubmitted && !isValid}
          type="submit"
        >
          Sign up
        </Button>
      </Stack>
    </>
  );
};
