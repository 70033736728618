import { ApolloError, gql } from '@apollo/client';

import { useQueryWithPastDataDeprecated } from '@/imports/ui/useQueryWithPastData';

import { useSSOProvider_tenantQuery } from './graphql-types/useSSOProvider_tenantQuery';

const TENANT_QUERY = gql`
  query useSSOProvider_tenantQuery {
    tenant {
      result {
        _id
        ssoMetadata {
          ssoProvider
        }
      }
    }
  }
`;

export const useSSOProvider = (): {
  ssoMetadata: {
    ssoProvider?: string;
  };
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error } =
    useQueryWithPastDataDeprecated<useSSOProvider_tenantQuery>(TENANT_QUERY);

  const effectiveSSOProvider = data?.tenant?.result?.ssoMetadata?.ssoProvider;

  return {
    ssoMetadata: {
      ssoProvider: effectiveSSOProvider,
    },
    loading,
    error,
  };
};
