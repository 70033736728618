import { gql, useMutation } from '@apollo/client';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { EMAIL_PLACEHOLDER, REG_EXP } from '@/utilities';

import {
  ForgotPasswordForm_RequestResetPassword,
  ForgotPasswordForm_RequestResetPasswordVariables,
} from './graphql-types/ForgotPasswordForm_RequestResetPassword';

import { EmailSentMessage } from '../EmailSentMessage';

const requestResetPasswordAST = gql`
  mutation ForgotPasswordForm_RequestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;

type ForgotPasswordFormValues = {
  email: string;
};

export const ForgotPasswordForm: React.FC<{
  onClickLogIn: () => void;
  modal?: boolean;
}> = (props) => {
  const [emailSent, setEmailSent] = React.useState(false);
  const [requestResetPassword, { loading, error }] = useMutation<
    ForgotPasswordForm_RequestResetPassword,
    ForgotPasswordForm_RequestResetPasswordVariables
  >(requestResetPasswordAST, { onCompleted: () => setEmailSent(true) });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = useForm<ForgotPasswordFormValues>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async ({
    email,
  }: ForgotPasswordFormValues): Promise<void> => {
    await requestResetPassword({
      variables: {
        email,
      },
    });
  };

  return emailSent ? (
    <EmailSentMessage
      headerText={`Great! We've sent you an email`}
      detailText={'Check your inbox for an email to reset your password'}
      center={props.modal}
    />
  ) : (
    <Stack as="form" spacing="6" noValidate onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Alert status="error" mb="4">
          <AlertIcon />
          <AlertDescription>
            Sorry, there was an error requesting a password reset.
          </AlertDescription>
        </Alert>
      )}

      <Stack spacing="4">
        <Heading size="md">Reset your Password</Heading>
        <Text color="gray.600">
          Enter your email address, and we will send you a link to reset your
          password.
        </Text>
      </Stack>

      <FormControl isRequired isInvalid={!!errors.email}>
        <FormLabel requiredIndicator={<></>}>Email</FormLabel>
        <Input
          autoFocus
          type="email"
          placeholder={EMAIL_PLACEHOLDER}
          {...register('email', {
            required: 'Email is required',
            validate: {
              email: (value) =>
                !value ||
                REG_EXP.EmailWithTLD.test(value) ||
                'You must enter a valid email address',
            },
          })}
        />
        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
      </FormControl>

      <Button
        type="submit"
        w="full"
        mt="4"
        isLoading={loading}
        isDisabled={isSubmitted && !isValid}
      >
        Send Reset Link
      </Button>

      <Button variant="link" colorScheme="black" onClick={props.onClickLogIn}>
        Log in
      </Button>
    </Stack>
  );
};
