import {
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import * as React from 'react';

import {
  EAccountCreationPermission,
  ECommunityAccessConfig,
} from '@/graphql-types/globalTypes';
import { useCurrent } from '@/hooks/useCurrent';
import { useMemberManagement } from '@/hooks/useMemberManagement';
import { useAnalyticsLogger } from '@/imports/ui/amplitude/helpers';
import { ILoginOutcome } from '@/imports/ui/amplitude/types';

import { EmailSentMessage } from './components/EmailSentMessage';
import { ForgotPasswordForm } from './components/ForgotPasswordForm';
import { LogInForm } from './components/LogInForm';
import { SignUpForm } from './components/SignUpForm';
import { EPromptState } from './utils';

export const LogInSignUp: React.FC<{
  initialPromptState?: EPromptState;
  modal?: boolean;
  ssoProvider?: string;
  conferenceOptions?: {
    useConferenceHeaders: boolean;
    name: string;
  };
}> = (props) => {
  const [resetPassword, setResetPassword] = React.useState(false);
  const [verifyEmail, setVerifyEmail] = React.useState(false);
  const analyticsClient = useAnalyticsLogger();
  const { isActive: isMemberManagementActive } = useMemberManagement();
  const current = useCurrent();

  const allowSignUp =
    current.tenant?.accountCreationPermission ===
    EAccountCreationPermission.ANYONE;

  const isTenantPublic =
    current.tenant?.communityAccessConfig === ECommunityAccessConfig.PUBLIC;
  React.useEffect(() => {
    analyticsClient.logEvent_login_view_loginUi({
      view: props.modal ? 'modal' : 'wall',
      'sso enabled': !!props.ssoProvider,
      'signup enabled': allowSignUp,
      'is public': isTenantPublic,
    });
  }, [
    analyticsClient,
    props.modal,
    allowSignUp,
    props.ssoProvider,
    isTenantPublic,
  ]);

  const onClickResetPassword = (): void => {
    analyticsClient.logEvent_login_click_resetPw({
      view: props.modal ? 'modal' : 'wall',
      'sso enabled': !!props.ssoProvider,
      'signup enabled': allowSignUp,
    });
    setResetPassword(true);
  };

  const onSubmitLogin = (): void => {
    analyticsClient.logEvent_login_click_login({
      view: props.modal ? 'modal' : 'wall',
      'sso enabled': !!props.ssoProvider,
      'signup enabled': allowSignUp,
    });
  };

  const onClickSSO = (): void => {
    analyticsClient.logEvent_login_click_continue({
      'sso provider': props.ssoProvider || 'none',
      view: props.modal ? 'modal' : 'wall',
      'signup enabled': allowSignUp,
    });
  };

  const onSubmitSignUp = (): void => {
    setVerifyEmail(true);
    analyticsClient.logEvent_login_click_signUp({
      view: props.modal ? 'modal' : 'wall',
      'sso enabled': !!props.ssoProvider,
    });
  };

  const onLoginOutcome = (outcome: ILoginOutcome): void => {
    analyticsClient.logEvent_login_outcome({
      outcome: outcome,
      view: props.modal ? 'modal' : 'wall',
      'sso enabled': !!props.ssoProvider,
      'signup enabled': allowSignUp,
    });
  };

  const loginHeader = props.conferenceOptions?.useConferenceHeaders
    ? `Log in to ${current.tenant?.displayName} to complete your registration for the ${props.conferenceOptions.name}.`
    : undefined;

  const signUpHeader = props.conferenceOptions?.useConferenceHeaders
    ? `Create a new account in ${current.tenant?.displayName} to complete your registration for the ${props.conferenceOptions.name}.`
    : undefined;

  return verifyEmail ? (
    <EmailSentMessage
      headerText="We sent you a verification email!"
      detailText="Check your email and click the verification link to finish creating your account."
      center={props.modal}
    />
  ) : resetPassword ? (
    <ForgotPasswordForm
      onClickLogIn={() => setResetPassword(false)}
      modal={props.modal}
    />
  ) : allowSignUp ? (
    <Stack spacing="6" w="full">
      <Tabs
        defaultIndex={props.initialPromptState}
        variant="solid-rounded"
        align={props.modal ? 'center' : undefined}
      >
        <TabList mb="8">
          <Tab>Sign in</Tab>
          <Tab>Sign up</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <LogInForm
              acronym={current.tenant?.acronym || undefined}
              onClickResetPassword={onClickResetPassword}
              header={loginHeader}
              ssoProvider={props.ssoProvider}
              onSubmitLogin={onSubmitLogin}
              onLoginOutcome={onLoginOutcome}
              onClickSSO={onClickSSO}
            />
          </TabPanel>
          <TabPanel>
            <SignUpForm header={signUpHeader} onSubmit={onSubmitSignUp} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {isMemberManagementActive && (
        <NextLink href="/memberships">
          <Button
            w="full"
            display={['inherit', 'none']}
            variant="outline"
            colorScheme="gray"
          >
            Explore Memberships
          </Button>
        </NextLink>
      )}
    </Stack>
  ) : (
    <LogInForm
      acronym={current.tenant?.acronym || undefined}
      onClickResetPassword={onClickResetPassword}
      header={loginHeader}
      ssoProvider={props.ssoProvider}
      onSubmitLogin={onSubmitLogin}
      onLoginOutcome={onLoginOutcome}
      onClickSSO={onClickSSO}
    />
  );
};

LogInSignUp.defaultProps = {
  initialPromptState: EPromptState.LOGIN_FORM,
};
