import { Box, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';

import { Emoji } from '@/imports/ui/chakra/components/Emoji';

export const EmailSentMessage: React.FC<{
  headerText: string;
  detailText: string;
  center?: boolean;
}> = (props) => {
  return (
    <Box textAlign={props.center ? 'center' : 'left'} maxW="sm">
      <Heading size="md" mb="4">
        <Emoji label="mail-emoji" symbol="✉️" fontSize="3xl" mb="2" />
        <br />
        {props.headerText}
      </Heading>
      <Text color="gray.600">{props.detailText}</Text>
    </Box>
  );
};
